<template>
  <List
    resource="apps"
    :basePath="basePath"
    :fields="fields"
    :request-params="{nonDeleted: true}"
    ref="list"
    on-row-click="edit"
    :filter-classes="{container: 'w-2/3 items-end', quickFilters: 'w-1/2 mr-6', searchContainer: 'flex-1'}"
    search
    search-placeholder="Search for an app by name"
  >
    <template v-slot:quickfilter="{applyFilter}">
      <div class="form-col">
        <label class="label">bundle</label>
        <Dropdown
          :options="bundleFilterOptions"
          :value="bundleFilter"
          @input="v => { if (!!v && v !== bundleFilter) { applyFilter(mapFilterToQuery(v)); } }"
          show-all
          noClear
        />
      </div>
    </template>
  </List>
</template>

<script>
  import List from '@/components/auth/list/List';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import AppStatusField from "@/components/auth/list/fields/AppStatusField";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Dropdown from "@/components/ui/Dropdown";
  import { BUNDLE_TYPES } from '@/components/auth/constants';

  export default {
    name: 'ApplicationsList',
    components: {Dropdown, List},
    mixins: [ModalNavigation, NotifyMixin],
    data: function () {
      return {
        bundles: [],
        bundleFilter: BUNDLE_TYPES.ALL,
        fields: [
          {
            name: 'name',
            sortField: 'name',
            title: 'Application name',
            width: '25%',
          },
          {
            name: 'apiEndpoint',
            sortField: 'apiEndpoint',
            title: 'API endpoint',
            width: '25%',
          },
          {
            name: 'bundle',
            title: 'bundle',
            sortField: 'type&bundle.name',
            formatter: (value) => {
              if (value == null) return '';

              if (value.type === BUNDLE_TYPES.SUITE) {
                return value.name;
              }

              return 'Stand alone app';
            },
            width: '20%',
          },
          {
            name: AppStatusField,
            sortField: 'status',
            title: 'Status',
          },
          {
            name: 'version',
            sortField: 'version',
            title: 'Version',
            titleClass: 'text-center',
            dataClass: 'text-center',
          }
        ]
      }
    },
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      bundleFilterOptions() {
        const suits = this.bundles.map((b) => ({ key: b.id, value: b.name.toLocaleLowerCase() }));
        return [
          { key: BUNDLE_TYPES.ALL, value: 'All' },
          { key: BUNDLE_TYPES.STAND_ALONE, value: 'Stand alone app' },
            ...suits,
        ];
      },
    },
    methods: {
      mapFilterToQuery(value) {
        this.bundleFilter = value;

        if (!value || value === BUNDLE_TYPES.ALL) { return {}; }

        if (value === BUNDLE_TYPES.STAND_ALONE) {
          return {
            type: BUNDLE_TYPES.STAND_ALONE
          };
        }
        return {
          bundleIds: value,
        };
      },
      fetchBundles() {
        this.$authDataProvider.getList('bundles', { size: 999, type: BUNDLE_TYPES.SUITE, nonDeleted: true })
          .then(({content}) => {
            this.bundles = content;
          }).catch((err) => this.notifyError(err.message));
      }
    },
    mounted() {
      this.fetchBundles();
    }
  };
</script>
